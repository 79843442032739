/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import { dashboardTrail } from "../../utils/dashboardBreadcrumb"
import LayoutDashborad from "../../components/Layout/Dashboard"
import Marketing from "../../templates/Dashboard/ManageSubscriptions/Marketing"
import Subscriptions from "../../templates/Dashboard/ManageSubscriptions/Subscriptions"
import { Tab } from "@headlessui/react"
import { classNames } from "../../utils"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import AccessDenied from "../../components/AccessDenied"
function ManageSubscriptions() {
  const Tabs = ({ tabs }) => {
    return (
      <div>
        <Tab.Group defaultIndex={0}>
          <Tab.List className="-mb-px flex space-x-8 border-b border-[#EBEBEB]">
            {tabs.map((item, k) => (
              <Tab
                key={k}
                className={({ selected }) =>
                  classNames(
                    selected
                      ? "border-[#222] text-[#222]"
                      : "border-transparent text-[#222] hover:text-gray-500",
                    "whitespace-nowrap pb-[28px] px-1 border-b-2 text-med-16"
                  )
                }
              >
                {item.name}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="pt-[30px]">
            {tabs.map((item, k) => {
              return <Tab.Panel key={k}>{item.content}</Tab.Panel>
            })}
          </Tab.Panels>
        </Tab.Group>
      </div>
    )
  }

  const { userType } = useContext(AuthenticationContext)

  const [tabs, setTabs] = useState([])
  useEffect(() => {
    if (userType.brandManager === true || userType.retailerManager === true) {
      setTabs([
        {
          name: "Notifications & Activity",
          content: <Subscriptions />,
        },
        {
          name: "Marketing & Newsletters",
          content: <Marketing />,
        },
      ])
    } else {
      setTabs([
        {
          name: "Marketing & Newsletters",
          content: <Marketing />,
        },
      ])
    }
  }, [userType])

  if (!userType.brandManager && !userType.retailerManager)
    return <AccessDenied />
  return (
    <LayoutDashborad
      heading="Manage Subscriptions"
      subheading="Manage your subscription preferences for O2O notifications, updates and marketing campaigns."
      trail={[...dashboardTrail, { text: "Manage Subscriptions", link: "#" }]}
      headingMargin={!userType.brandManager && "mt-20"}
    >
      {tabs.length > 0 && <Tabs tabs={tabs} />}
    </LayoutDashborad>
  )
}
export default ManageSubscriptions
