/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import { dashboardTrail } from "../../../utils/dashboardBreadcrumb"
import LayoutDashborad from "../../../components/Layout/Dashboard"
import SectionCard from "../../../components/Cards/Dashboard/SectionCard"
import Button from "../../../components/Elements/Button"
import FloatingSelect from "../../../components/Elements/Form/FloatingSelect"
import { Table, Tr, Td } from "../../../components/Table/TableElements"
import StateContext from "../../../context/state/StateContext"
import DataContext from "../../../context/Data/DataContext"
import AuthenticationContext from "../../../context/authentication/AuthenticationContext"
import IconClose from "../../../components/Elements/IconClose"
import { PlusCircleIcon } from "@heroicons/react/outline"
import {
  getUserSubscriptions,
  updateUserSubscriptions,
  getCompanyNotificationSettings,
  getSubUserListing,
  getRetailStoresByCompany,
  saveCompanyNotificationSettings,
} from "../../../helpers/DrupalHelper"
import NotificationContext from "../../../context/Notification/NotificationContext"
import Skeleton from "../../../components/Skeleton"
import { Tab } from "@headlessui/react"
import { classNames } from "../../../utils"

const Checkbox = () => {
  return (
    <label className="inline-flex items-center">
      <input
        type="checkbox"
        className="w-6 h-6 rounded"
        defaultChecked={true}
      />
      <span className="ml-2"></span>
    </label>
  )
}
function Subscriptions() {
  const { state } = useContext(StateContext)
  const { toggleNotification } = useContext(NotificationContext)
  const { userType } = useContext(AuthenticationContext)
  const { currentUserCompaniesAsOp, myBrands } = useContext(DataContext).data
  const [company, setCompany] = useState(
    currentUserCompaniesAsOp ? currentUserCompaniesAsOp[0] : false
  )
  const [subs, setSubs] = useState([])
  const [stores, setStores] = useState([])
  const [notifSettings, setNotifSettings] = useState([])
  const [loadingRules, setLoadingRules] = useState(false)
  const [initLoading, setInitLoading] = useState(true)

  useEffect(async () => {
    if (company?.value) {
      let data = await getCompanyNotificationSettings(
        state.token,
        company.value
      )
      setNotifSettings([...data])
      setInitLoading(false)

      let subs = await getSubUserListing(state.token, company.value)
      setSubs([...subs])

      let companyStores = await getRetailStoresByCompany(
        state.token,
        company.value
      )
      setStores(companyStores)
    }
  }, [company])

  useEffect(async () => {
    if (currentUserCompaniesAsOp) {
      setCompany(currentUserCompaniesAsOp[0])
    }
  }, [currentUserCompaniesAsOp])

  const onSaveRules = async () => {
    let allValue = []
    notifSettings.map(item => {
      Object.keys(item)
        .sort(
          (a, b) =>
            Object.keys(columnsArr).indexOf(a) -
            Object.keys(columnsArr).indexOf(b)
        )
        .map(i => {
          if (i === "users" && item[i].length) allValue.push(true)
          else if (
            (userType.retailerManager === true && i === "brands") ||
            (userType.brandManager === true && i === "brands" && item[i].length)
          )
            allValue.push(true)
          else if (
            (userType.brandManager === true && i === "stores") ||
            (userType.retailerManager && i === "stores" && item[i].length)
          )
            allValue.push(true)
          else if (
            i === "channels" &&
            Object.values(item[i]).filter(v => v).length
          )
            allValue.push(true)
          else if (i === "type" && item[i].length > 0) allValue.push(true)
          else {
            allValue.push(false)
          }
        })
    })

    if (!allValue.includes(false)) {
      //let user submit the form

      setLoadingRules(true)

      let res = await saveCompanyNotificationSettings(
        state.token,
        company.value,
        notifSettings
      )
      if (res.status === 200) {
        toggleNotification({
          content: "Successfully updated notification rules.",
        })
      } else {
        toggleNotification({
          content: "Failed to update Subscriptions",
          error: true,
        })
      }
      setLoadingRules(false)
    } else {
      setShowError(true)
    }
  }

  let types = [
    {
      value: "message_brand",
      label: "Brand Messages",
    },
    {
      value: "request_asset",
      label: "Asset Requests",
    },
    // {
    //   value: "user_updates",
    //   label: "User Updates",
    // },
    // {
    //   value: "order_status",
    //   label: "Order Status",
    // },

    // {
    //   value: "message",
    //   label: "Message",
    // },
  ]

  if (userType.retailerManager === true)
    types = [
      ...types,
      {
        value: "store_profile_changes",
        label: "Store Profile Changes",
      },
    ]
  if (myBrands.length)
    types = [
      ...types,
      {
        value: "inventory_change",
        label: "Inventory Alerts",
      },
      {
        value: "inventory_submissions",
        label: "Inventory Submissions",
      },
      {
        value: "request_visit",
        label: "Visit Requests",
      },
      {
        value: "brand_profile_changes",
        label: "Brand Profile Changes",
      },

      {
        value: "order_fulfillment_third_party",
        label: "Third-party Fulfillment",
      },
      {
        value: "order_fulfillment_in_house",
        label: "In-house Fulfillment",
      },
      // {
      //   value: "message_brand",
      //   label: "Brand Message",
      // },
    ]

  types = types.sort((a, b) => a.label.localeCompare(b.label))

  // const [availableTypes, setAvailabeTypes] = useState(types)
  //
  // useEffect(() => {
  //   if (notifSettings.length) {
  //     const excluded = []
  //     notifSettings.map((item, k) => {
  //       excluded.push(item.type)
  //     })
  //     setAvailabeTypes(types.filter(f => !excluded.includes(f.value)))
  //   }
  // }, [notifSettings])

  // let columnsArr = {
  //   type: "Type",
  //   brands: "Brands",
  //   stores: "Stores",
  //   users: "Users",
  //   channels: "Channels",
  //   delete: "Delete",
  // }

  let columnsArr = {}
  columnsArr.type = "Type"
  if (userType.brandManager === true) columnsArr.brands = "Brands"
  if (userType.retailerManager === true) columnsArr.stores = "Stores"
  columnsArr.users = "Users"
  columnsArr.channels = "Channels"
  columnsArr.delete = "Delete"

  let channelTypes = {
    email: "Email",
    notification: "Notification",
    message: "Message",
  }
  const addRule = () => {
    let newRule = {
      type: "",
      brands: [],
      stores: [],
      channels: [],
      users: [],
    }
    setNotifSettings(prevState => [...prevState, newRule])
  }
  const requireMessageType = [
    "request_asset",
    "request_visit",
    "brand_messages",
  ]
  const updateRule = (ruleIndex, fieldName, fieldValue) => {
    let newRule = JSON.parse(JSON.stringify(notifSettings[ruleIndex]))
    console.log(newRule, ruleIndex, fieldName, fieldValue)
    if (fieldName == "type" && requireMessageType.includes(fieldValue.value)) {
      if (!newRule["channels"].includes("message")) {
        newRule["channels"] = [...newRule["channels"], "message"]
      }
    }
    if (
      fieldName === "channels" &&
      fieldValue[Object.keys(fieldValue)[0]] === true
    ) {
      console.log("a")
      newRule[fieldName] = [...newRule[fieldName], Object.keys(fieldValue)[0]]
    } else if (
      fieldName === "channels" &&
      fieldValue[Object.keys(fieldValue)[0]] === false
    ) {
      console.log("b")
      newRule[fieldName] = [
        ...newRule[fieldName].filter(f => f !== Object.keys(fieldValue)[0]),
      ]
    } else if (
      typeof newRule[fieldName] === "string" &&
      typeof fieldValue === "object"
    ) {
      console.log("c")
      newRule[fieldName] = fieldValue.value
      console.log("what")
    } else if (Array.isArray(newRule[fieldName]) && fieldName === "channels") {
      console.log("d")
      newRule[fieldName] = fieldValue
    } else if (Array.isArray(newRule[fieldName])) {
      console.log("e")
      newRule[fieldName] = fieldValue.map((i, k) =>
        Object.assign({ name: i.label, id: i.value })
      )
    }

    let newRules = JSON.parse(JSON.stringify(notifSettings))
    newRules[ruleIndex] = newRule
    console.log(newRules, "new")
    setNotifSettings(newRules)
  }

  const deleteRule = ruleIndex => {
    let newRules = JSON.parse(JSON.stringify(notifSettings)).filter(
      (f, k) => k !== ruleIndex
    )
    setNotifSettings(newRules)
  }

  // console.log(myBrandsAsOptions, "brands")
  const [showError, setShowError] = useState(false)
  return (
    <>
      {currentUserCompaniesAsOp && currentUserCompaniesAsOp.length > 1 && (
        <div className="max-w-[200px]">
          <FloatingSelect
            options={currentUserCompaniesAsOp}
            defaultValue={currentUserCompaniesAsOp[0]}
            label={"Choose company"}
            onChange={v => setCompany(v)}
          />
        </div>
      )}
      <SectionCard>
        <div
          css={css`
            th {
              padding-top: 0 !important;
            }
            th:last-child {
              width: 60px;
              text-align: right !important;
            }
            th:nth-child(5) {
              width: 120px;
            }
          `}
        >
          <Table
            flush
            flushHead
            fit
            noBorder
            overflowVisible
            columns={Object.keys(columnsArr).map(i => columnsArr[i])}
          >
            {notifSettings.map((item, k) => {
              return (
                <Tr className="border border-b !border-b-[1px] border-l-0 border-r-0 border-t-0">
                  {Object.keys(item)
                    .sort(
                      (a, b) =>
                        Object.keys(columnsArr).indexOf(a) -
                        Object.keys(columnsArr).indexOf(b)
                    )
                    .map(i => {
                      if (i === "users") {
                        let usersToExclude = []
                        notifSettings
                          .filter(
                            f => f.type === item.type && f.users.length > 0
                          )
                          .map(f =>
                            usersToExclude.push(...f.users.map(x => x.id))
                          )

                        return (
                          <Td className="!pl-0 !pr-2 py-6">
                              <FloatingSelect
                                wrapMultiItems
                                className={"lg:!my-4"}
                                label="Users"
                                isMulti={true}
                                options={subs
                                  .filter(f => !usersToExclude.includes(f.id))
                                  .map(e =>
                                    Object.assign({ value: e.id, label: e.name })
                                  )}
                                value={item[i].map(e =>
                                  Object.assign({ value: e.id, label: e.name })
                                )}
                                onChange={val => {
                                  updateRule(k, i, val)
                                }}
                                error={
                                  item[i].map(e =>
                                    Object.assign({ value: e.id, label: e.name })
                                  ).length
                                    ? ""
                                    : showError && "This field is required"
                                }
                              />
                          </Td>
                        )
                      }
                      if (i === "brands" && userType.brandManager === true)
                        return (
                          <Td className="!pl-0 !pr-2">
                            <FloatingSelect
                              className={"lg:!my-4"}
                              label="Brands"
                              isMulti={true}
                              options={myBrands
                                .filter(
                                  f =>
                                    parseInt(f.company_id) ===
                                    parseInt(company.value)
                                )
                                .map(i =>
                                  Object.assign({
                                    value: parseInt(i.id),
                                    label: i.title,
                                  })
                                )}
                              value={item[i].map(e =>
                                Object.assign({ value: e.id, label: e.name })
                              )}
                              onChange={val => {
                                updateRule(k, i, val)
                              }}
                              error={
                                item[i].length
                                  ? ""
                                  : showError && "This field is required"
                              }
                            />
                          </Td>
                        )
                      if (i === "stores" && userType.retailerManager === true)
                        return (
                          <Td className="!pl-0 !pr-2">
                            <FloatingSelect
                              className={"lg:!my-4"}
                              label="Stores"
                              isMulti={true}
                              options={stores.map(e =>
                                Object.assign({ value: e.id, label: e.name })
                              )}
                              value={item[i].map(e =>
                                Object.assign({ value: e.id, label: e.name })
                              )}
                              onChange={val => {
                                updateRule(k, i, val)
                              }}
                              error={
                                item[i].length
                                  ? ""
                                  : showError && "This field is required"
                              }
                            />
                          </Td>
                        )

                      if (i === "channels")
                        return (
                          <Td className="!pl-0 !pr-2">
                            <div className="flex flex-col space-y-1 leading-[12px]">
                              {Object.keys(channelTypes).map((channel, ch) => {
                                return (
                                  <>
                                    <label className="inline-flex items-center">
                                      {console.log(item.type)}
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4 rounded"
                                        onChange={a => {
                                          // console.log(a)
                                          if (
                                            channel === "message" &&
                                            requireMessageType.includes(
                                              item.type
                                            )
                                          )
                                            return
                                          else
                                            updateRule(k, i, {
                                              [channel]: a.target.checked,
                                            })
                                        }}
                                        checked={
                                          channel == "message" &&
                                            requireMessageType.includes(item.type)
                                            ? true
                                            : item[i].includes(channel)
                                        }
                                        defaultChecked={item[i].includes(
                                          channel
                                        )}
                                      />
                                      <span className="ml-2">
                                        {channelTypes[channel]}
                                      </span>
                                    </label>
                                  </>
                                )
                              })}
                              <span className="text-[#E43D30]">
                                {Object.values(item[i]).filter(v => v).length
                                  ? ""
                                  : showError && "Please Select a channel"}
                              </span>
                            </div>
                          </Td>
                        )

                      if (i === "type")
                        return (
                          <Td className="!pl-0 !pr-2">
                            <FloatingSelect
                              className={"lg:!my-4"}
                              options={types}
                              value={types.filter(f => f.value === item[i])}
                              label={"Type"}
                              onChange={val => {
                                updateRule(k, i, val)
                              }}
                              error={
                                item[i] ? "" : showError && "Please Select Type"
                              }
                            />
                          </Td>
                        )
                      return null
                    })}
                  <Td className="!pl-0 !pr-2">
                    <div className="float-right">
                      <IconClose
                        callback={() => deleteRule(k)}
                        small
                        height={2}
                      />
                    </div>
                  </Td>
                </Tr>
              )
            })}
            {notifSettings.length === 0 && initLoading === false && (
              <Tr>
                <Td>You have no rules yet.</Td>
              </Tr>
            )}
            {initLoading === true &&
              [0, 1, 2].map((i, k) => {
                return (
                  <Tr>
                    <Td className="!pl-0 !pr-2">
                      <Skeleton height={50} width={"100%"} rounded={"8px"} />
                    </Td>
                    <Td className="!pl-0 !pr-2">
                      <Skeleton height={50} width={"100%"} rounded={"8px"} />
                    </Td>
                    <Td className="!pl-0 !pr-2">
                      <Skeleton height={50} width={"100%"} rounded={"8px"} />
                    </Td>
                    <Td className="!pl-0 !pr-2">
                      <Skeleton height={50} width={"100%"} rounded={"8px"} />
                    </Td>
                    <Td className="!pl-0 !pr-2">
                      <Skeleton height={50} width={"100%"} rounded={"8px"} />
                    </Td>
                    <Td className="!pl-0 !pr-2">
                      <Skeleton height={50} width={"100%"} rounded={"8px"} />
                    </Td>
                  </Tr>
                )
              })}
          </Table>
        </div>

        <div className="mt-6 mb-[30px] md:mb-0 flex items-center space-x-2">
          <div>
            {" "}
            <Button
              pink
              onClick={() => addRule()}
              disabled={
                initLoading
                // ||notifSettings.length === types.length
              }
            >
              <span className="flex justify-center items-center">
                <PlusCircleIcon className={"h-4 w-4 mr-1"} /> Add Rule
              </span>
            </Button>
            {/*(availableTypes.length === 0 ||
              notifSettings.length === types.length) && (
              <p className="text-reg-16">
                You've already created rules for each notification type.
              </p>
            )*/}
          </div>
          <div className="!ml-auto">
            <Button
              red
              onClick={() => onSaveRules()}
              loading={loadingRules}
              disabled={initLoading}
            >
              Save Rules
            </Button>
          </div>
        </div>
      </SectionCard>
    </>
  )
}
export default Subscriptions
