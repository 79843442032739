/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import { dashboardTrail } from "../../../utils/dashboardBreadcrumb"
import LayoutDashborad from "../../../components/Layout/Dashboard"
import SectionCard from "../../../components/Cards/Dashboard/SectionCard"
import Button from "../../../components/Elements/Button"
import FloatingSelect from "../../../components/Elements/Form/FloatingSelect"
import { Table, Tr, Td } from "../../../components/Table/TableElements"
import StateContext from "../../../context/state/StateContext"
import DataContext from "../../../context/Data/DataContext"
import IconClose from "../../../components/Elements/IconClose"
import { PlusCircleIcon } from "@heroicons/react/outline"
import {
  getUserSubscriptions,
  updateUserSubscriptions,
  getCompanyNotificationSettings,
  getSubUserListing,
  getRetailStoresByCompany,
} from "../../../helpers/DrupalHelper"
import NotificationContext from "../../../context/Notification/NotificationContext"
import Skeleton from "../../../components/Skeleton"
import { Tab } from "@headlessui/react"
import { classNames } from "../../../utils"

const Checkbox = () => {
  return (
    <label className="inline-flex items-center">
      <input
        type="checkbox"
        className="w-6 h-6 rounded"
        defaultChecked={true}
      />
      <span className="ml-2"></span>
    </label>
  )
}
function Marketing() {
  const { state } = useContext(StateContext)
  const { toggleNotification } = useContext(NotificationContext)

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    let checkBoxData = await getUserSubscriptions(state.token)
    setData(checkBoxData)
  }, [])
  const onSaveMarketing = async () => {
    setLoading(true)
    let res = await updateUserSubscriptions(state.token, data)
    if (res.status === 200) {
      toggleNotification({ content: "Successfully updated Subscriptions" })
    } else {
      toggleNotification({
        content: "Failed to update Subscriptions",
        error: true,
      })
    }
    setLoading(false)
  }

  const onChange = (e, item) => {
    let opToChange = data.filter(f => f.name === item.name)[0]
    let otherOps = data.filter(f => f.name !== item.name)
    let newObj = [...otherOps]
    let changedOp
    if (e.target.checked) {
      changedOp = { ...opToChange, subscribed: true }
    } else {
      changedOp = { ...opToChange, subscribed: false }
    }
    newObj.push(changedOp)
    setData(newObj)
  }

  return (
    <SectionCard>
      {data !== null && data.length > 0 ? (
        <div className="mb-[30px] md:mb-0">
          <div className="mb-4">
            {data
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((item, k) => {
                return (
                  <div className="block" key={k}>
                    <div className="my-3">
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          className="w-6 h-6 rounded"
                          onChange={e => {
                            onChange(e, item)
                          }}
                          defaultChecked={item.subscribed}
                        />
                        <span className="ml-2">{item.name}</span>
                      </label>
                    </div>
                  </div>
                )
              })}
          </div>
          <Button red onClick={() => onSaveMarketing()} loading={loading}>
            Save
          </Button>
        </div>
      ) : (
        <div className="flex flex-col space-y-2 max-w-[300px]">
          <Skeleton height={38} width={300} rounded={"8px"} dark />
          <Skeleton height={38} width={300} rounded={"8px"} />
          <Skeleton height={38} width={300} rounded={"8px"} dark />
          <Skeleton height={38} width={300} rounded={"8px"} />
          <Button red disabled>
            Save
          </Button>
        </div>
      )}
    </SectionCard>
  )
}
export default Marketing
